import React from 'react'

// import SvgExpandLeftIcon from '../../Icons/ExpandLeftIcon'
// import SvgExpandRightIcon from "../../Icons/ExpandRightIcon";
import Tab from '../../Tab'
import TabHolder from '../../TabHolder'
// import ReactPlayer from 'react-player'

import styles from './LandingPanel.module.css'

const LandingPanel = ({
  setModalVisible,
  setIntention,
  setWideView,
  setProjectsOpen,
  projectsOpen,
  wideView,
  windowSize,
  featuredData,
  setAboutFocus,
  aboutFocus,
  setRecentFocus,
  recentFocus
}) => {
  //   const [aboutFocus, setAboutFocus] = useState(false)
  const desktopMode = windowSize.width > 750
  const veryShortPage = windowSize.height < 450
  const namePanelAndFilterStyle = {
    width: desktopMode ? '436px' : '100%',
    // transition: 'width ease-in-out 0.5s',
    position: desktopMode ? 'relative' : 'absolute',
    minWidth: '300px',
    left: 0 //  aboutFocus ? '-100%' : 0
  }

  return (
    <div className={styles.namePanelAndFilter} style={namePanelAndFilterStyle}>
      {/* <div
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'absolute'
        }}
      >
        <video
          height={'100%'}
          url='https://www.pexels.com/video/a-rocky-river-in-the-forest-5896379/'
        />
      </div> */}
      <div className={styles.info}>
        <div className={styles.titleAndIconList}>
          <div
            className={styles.title}
            style={{
              fontSize: veryShortPage ? '2.5rem' : 'var(--font-size-lg)',
              width: veryShortPage ? 'auto' : '207px',
              height: veryShortPage ? 'auto' : '156px'
            }}
          >
            Stuart Mellor
          </div>
        </div>
        <div className={styles.isASoundContainer}>
          <p
            className={styles.isASound}
            style={{
              fontSize: veryShortPage ? '1.1rem' : '1.3rem',
              margin: veryShortPage ? '5px 0' : '20px 0'
            }}
          >
            is a Sound Artist, Doctorate Researcher, and Creative Technologist
          </p>
          <div
            id='tempboi'
            style={{
              height: windowSize.height > '897' ? 'fit-content' : 0,
              opacity: windowSize.height > '897' ? 1 : 0,
              transition: 'height 0.5s ease-in-out, opacity 0.25s ease-in-out',
              overflowY: 'hidden'
            }}
          >
            <p className={styles.isASound} style={{ fontSize: '1.3rem' }}>
              working with/on:
            </p>

            <TabHolder>
              <Tab text={'Rivers'} />
              <Tab text={'Acoustics'} />
              <Tab text={'Max MSP'} />
              <Tab text={'Ecoacoustics'} />
            </TabHolder>

            <p className={styles.isASound} style={{ fontSize: '1.3rem' }}>
              ... with skills in:
            </p>

            <TabHolder>
              <Tab text={'C++'} />
              <Tab text={'Javascript'} />
              <Tab text={'React'} />
              <Tab text={'Sound Recording'} />
              <Tab text={'Full MERN Stack'} />
            </TabHolder>
          </div>
          <div
            className={styles.hireMeContainer}
            style={{
              marginTop: veryShortPage ? '10px' : '50px'
            }}
          >
            <button
              onClick={() => {
                setIntention('talk')
                setModalVisible(true)
              }}
              className={styles.hireMe}
            >
              HIRE ME
            </button>
            <div style={{ width: '10px', height: '20px' }} />
            <button
              onClick={() => {
                setIntention('hire')
                setModalVisible(true)
              }}
              className={styles.hireMe}
            >
              TALK TO ME
            </button>
          </div>
        </div>
        {/* {!desktopMode && (
          <button
            onClick={() => {
              console.log('Setting recent focus to true!')
              setRecentFocus(true)
            }}
            style={{
              font: 'var(--font-inter)',
              margin: 0,
              background: 'none',
              // borderRadius: '15px',
              border: 'none',
              width: '100%',
              paddingRight: '50px',
              height: '50px',
              position: 'absolute',
              bottom: 0,
              left: 0
            }}
          >
            <h4
              style={{
                margin: '0 0 0 0',
                textAlign: 'right',
                color: 'black'
              }}
            >
              Recent Work -{'>'}
            </h4>
          </button>
        )} */}
      </div>
    </div>
  )
}

export default LandingPanel
