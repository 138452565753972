import React, { useState } from 'react'
import styles from './TopFrame.module.css'
import About from './About/About'
import LandingPanel from './LandingPanel/LandingPanel'
import Modal from '../Modal/modal'

const TopFrame = ({ carouselMode, windowSize, sizeMode }) => {
  const [wideView, setWideView] = useState(false)
  const [showModal, setModalVisible] = useState(false)
  const [intention, setIntention] = useState('talk')
  const [projectsOpen, setProjectsOpen] = useState(false)
  const [aboutFocus, setAboutFocus] = useState(false)
  const [recentFocus, setRecentFocus] = useState(false)

  const topFrameStyle = {
    height: recentFocus ? '0' : '100%',
    overflowY: 'hidden'
  }

  const desktopMode = windowSize.width > 750

  return (
    <div className={styles.topFrame} style={topFrameStyle}>
      <Modal
        sizeMode={sizeMode}
        intention={intention}
        show={showModal}
        setModalVisible={setModalVisible}
      />
      {!desktopMode && (
        <button
          onClick={() => {
            setAboutFocus(!aboutFocus)
          }}
          style={{
            font: 'var(--font-inter)',
            margin: 0,
            background: 'none',
            // borderRadius: '15px',
            border: 'none',
            width: aboutFocus ? '180px' : '100%',
            minWidth: 'fit-content',
            paddingRight: '50px',
            height: '50px',
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: '2',
            transition: 'width .3s ease'
          }}
        >
          <h4
            style={{
              margin: '0 0 0 0',
              textAlign: 'right',
              color: 'black',
              minWidth: 'fit-content'
            }}
          >
            About {aboutFocus ? 'x' : '->'}
          </h4>
        </button>
      )}
      <LandingPanel
        sizeMode={sizeMode}
        setIntention={setIntention}
        setModalVisible={setModalVisible}
        setWideView={setWideView}
        wideView={wideView}
        windowSize={windowSize}
        projectsOpen={projectsOpen}
        setProjectsOpen={setProjectsOpen}
        setAboutFocus={setAboutFocus}
        aboutFocus={aboutFocus}
        setRecentFocus={setRecentFocus}
        recentFocus={recentFocus}
      />
      <About
        sizeMode={sizeMode}
        setWideView={setWideView}
        wideView={wideView}
        carouselMode={carouselMode}
        windowSize={windowSize}
        setAboutFocus={setAboutFocus}
        aboutFocus={aboutFocus}
      />
    </div>
  )
}

export default TopFrame
