

const loadContent = (url, gotData) => {
    fetch(url)
    .then((response) => response.json())
    .then((data) => {
        gotData(null, data);
    }).catch(err => {
        gotData(err);
    });
};

export { loadContent };