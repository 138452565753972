import React from 'react'
import InfoPage from './InfoPage'

import styles from './About.module.css'

const About = ({
  carouselMode,
  wideView,
  setWideView,
  sizeMode,
  windowSize,
  aboutFocus,
  setAboutFocus
}) => {
  const narrowView = windowSize.width < 1250
  const mobileMode = windowSize.width < 750
  const shortView = windowSize.height < 650
  const veryShortPage = windowSize.height < 450

  const root = document.documentElement
  if (mobileMode) {
    root.style.setProperty('overflow', 'hidden')
  }

  console.log({ windowSize })
  const microMode =
    (windowSize.height < 800 && windowSize.width < 1250) ||
    windowSize.height < 700
  // windowSize.height < 600 windowSize.width < 1000)
  // const aboutPos = aboutFocus ? '0%' : '100%'
  return (
    <div
      className={styles.topFrameChild}
      style={{
        position: mobileMode ? 'absolute' : 'relative',
        // left: aboutFocus || !mobileMode ? '0' : 'auto',
        // right: aboutFocus || !mobileMode ? '0' : '-50%',
        width: '100%',
        display: aboutFocus || !mobileMode ? 'flex' : '',
        height: 'calc(100% - 20px)', //aboutFocus || !mobileMode ? '100%' : 'auto',
        opacity: aboutFocus || !mobileMode ? '1' : '0',
        transition: 'right ease-in-out 0.5s, opacity ease-in-out 0.3s',
        borderRight: mobileMode ? '10px solid var(--color-black)' : 'none',
        pointerEvents: mobileMode && !aboutFocus ? 'none' : 'all'
        // borderLeft: mobileMode ? '10px solid var(--color-black)' : 'none'
      }}
    >
      {!wideView && (
        <InfoPage
          narrowView={narrowView}
          shortView={shortView}
          sizeMode={sizeMode}
          microMode={microMode}
          aboutFocus={aboutFocus}
          mobileMode={mobileMode}
          veryShortPage={veryShortPage}
        />
      )}
    </div>
  )
}
export default About
