import React from 'react'
import styles from './ProjectList.module.css'

const ProjectListItem = ({
  projectData,
  setProjectUrl,
  isSelected,
  foldLevel
}) => {
  if (!projectData) {
    return <h6>Project Data Error!</h6>
  }

  const { date, title, url /*, whowith */ } = projectData
  // const itemClassName = isSelected
  //   ? styles.ProjectListItemSelected
  //   : styles.ProjectListItem
  let hasData = true
  if (url === '/projects/.json') hasData = false

  // let displayText = ''
  // if (!isSelected && foldLevel === 0) {
  //   displayText = `${date[0]}/${date[1]} - "${title}" - ${whowith}`
  // } else if (isSelected && foldLevel < 2) {
  //   displayText = `${title}`
  // } else if (isSelected && foldLevel >= 2) {
  //   displayText = `${title}`
  // } else if (foldLevel === 1) {
  //   displayText = `${date[0]}/${date[1]} - "${title}"`
  // } else if (foldLevel === 2) {
  //   displayText = `${title}`
  // }

  return (
    <tr
      className={styles.ProjectListItem}
      style={{
        background: isSelected ? '#172925' : 'none',
        // padding: isSelected ? '0 30px 0 0' : '0 5px 0 0',
        // fontSize: '1rem',
        // height: isSelected ? '45px' : '28px',
        fontSize: isSelected ? '1.3rem' : '1.0rem',
        color: hasData ? 'white' : 'rgba(255,255,255,0.7)'
      }}
    >
      {foldLevel > 3 && (
        <td className={styles.ProjectListItemDate}>
          {
            <button
              className={styles.ProjectListButton}
              onClick={() => {
                if (url !== '/projects/.json') setProjectUrl(url)
              }}
            >
              {`${date[0]}/${date[1]}`}
            </button>
          }
        </td>
      )}
      <td className={styles.tableTitle} style={{}}>
        <button
          className={styles.ProjectListButton}
          onClick={() => {
            if (url !== '/projects/.json') setProjectUrl(url)
          }}
          style={{
            fontSize: foldLevel >= 3 ? '0.85rem' : '1.1rem'
          }}
        >
          {title}
        </button>
      </td>
    </tr>
  )
}

export default ProjectListItem
