import React from 'react'
import styles from './ProjectList.module.css'
import ProjectListItem from './ProjectListItem'

const ProjectList = ({
  listOfProjects,
  setProjectUrl,
  projectUrl,
  windowWidth
}) => {
  if (listOfProjects) {
    let foldLevel = 0
    if (windowWidth < 1100) foldLevel = 1
    if (windowWidth < 900) foldLevel = 2
    if (windowWidth < 650) foldLevel = 3

    return (
      <div className={styles.ProjectList}>
        <p className={styles.ProjectListTitle}>Recent Work</p>
        <table className={styles.ProjectTable}>
          {listOfProjects.map((projectData, i) => {
            let isSelected =
              projectData.url === projectUrl &&
              projectData.url !== '/projects/.json'
            return (
              <ProjectListItem
                key={`projectlistitem-${i}`}
                projectData={projectData}
                setProjectUrl={setProjectUrl}
                isSelected={isSelected}
                foldLevel={foldLevel}
              />
            )
          })}
        </table>
      </div>
    )
  } else {
    return <div />
  }
}

export default ProjectList
