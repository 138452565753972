import TopFrame from './components/TopFrame/TopFrame'
import React, { useState, useEffect } from 'react'
import styles from './HomePage.module.css'
import isMobile from 'is-mobile'
import BottomFrame from './components/BottomFrame/BottomFrame'

const isMobileDevice = isMobile()

const HomePage = () => {
  const [carouselMode, setCarouselMode] = useState(false)

  const windowSize = useWindowSize()
  const windowWidth = windowSize.width

  // const [isFullscreen, setIsFullscreen] = React.useState(false)

  // // Watch for fullscreenchange
  // React.useEffect(() => {
  //   function onFullscreenChange () {
  //     setIsFullscreen(Boolean(document.fullscreenElement))
  //   }

  //   document.addEventListener('fullscreenchange', onFullscreenChange)

  //   return () =>
  //     document.removeEventListener('fullscreenchange', onFullscreenChange)
  // }, [])

  return (
    <div className={styles.homepage}>
      <TopFrame
        isMobileDevice={isMobileDevice}
        carouselMode={carouselMode}
        windowSize={windowSize}
      />
      <BottomFrame
        isMobileDevice={isMobileDevice}
        carouselMode={carouselMode}
        setCarouselMode={setCarouselMode}
        windowWidth={windowWidth}
      />
    </div>
  )
}

function useWindowSize () {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({ width: null, height: null })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize () {
      // Set window width/height to state

      setWindowSize({
        width: window.outerWidth,
        height: window.outerHeight
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export default HomePage
