import React, { useMemo, useState } from 'react'
import { loadContent } from '../../../SharedFuncs/loadContent'
import Tab from '../../Tab'

import styles from './ProjectDisplay.module.css'

const ProjectDisplay = ({ projectUrl, windowWidth }) => {
  const [projectData, setProjectData] = useState(null)

  const smallDisplay = windowWidth < 1150
  const narrowDisplay = windowWidth < 750

  const displayImageStyle = useMemo(() => {
    return {
      width: smallDisplay && !narrowDisplay ? '50%' : 'calc(100% - 20px)',
      // height: smallDisplay ? 'calc(100% - 20px)' : '160px',
      maxHeight: smallDisplay && !narrowDisplay ? '100%' : '160px'
    }
  }, [windowWidth])

  const projectDisplayStyle = useMemo(() => {
    return {
      width: smallDisplay ? 'calc(100% - 50px)' : 'calc(50% - 25px)'
    }
  }, [windowWidth])

  const displayTopStyle = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: smallDisplay && !narrowDisplay ? 'row-reverse' : 'column'
    }
  }, [windowWidth])

  useMemo(() => {
    loadContent(projectUrl, (err, data) => {
      if (err) {
        console.log(err)
      } else {
        // TODO Add some error catches!
        setProjectData(data)
      }
    })
  }, [projectUrl])

  if (!projectData) {
    return <div className={styles.emptyProjectDisplay}></div>
  }

  const {
    title,
    shortText,
    longText,
    imageUrls,
    imageAlt,
    date,
    tags,
    technologies
  } = projectData

  return (
    <div className={styles.projectDisplay} style={projectDisplayStyle}>
      <div className={styles.displayTop} style={displayTopStyle}>
        <img
          className={styles.ProjectDisplayImage}
          style={displayImageStyle}
          src={imageUrls[0]}
          alt={imageAlt}
        />

        {/*  */}
        <div className={styles.ProjectDisplayLeft} style={{ width: '100%' }}>
          <h2
            className={styles.ProjectDisplayTitle}
          >{`${title} (${date[0]}/${date[1]})`}</h2>
          {/* <a href={links ? links[0] : ""}>{links ? links[0] : ""}</a> */}
          <p
            className={styles.ProjectDisplayShortText}
            style={{ width: '100%' }}
          >
            {shortText}
          </p>
          <p className={styles.ProjectDisplayLongText}>{longText}</p>
        </div>
      </div>
      <div className={styles.ProjectDisplayBottom}>
        <div
          className={styles.ProjectDisplayRight}
          style={{ width: '100%' }}
          // windowWidth < 1400 ? '100%' : '50%' }}
        >
          {/* {soundUrls &&
                        <div style={{ width: '100%', height: '80px' }}>
                            <ReactPlayer url={soundUrls[0]} width="100%" height="100%" />
                        </div>
                    } */}
          <h6 className={styles.ProjectDisplayTagTitle}>Technologies</h6>
          <div className={styles.ProjectDisplayTabHolder}>
            {technologies.map((tech, i) => (
              <Tab key={`${tech}-i-tech`} text={tech} />
            ))}
          </div>
          <h6 className={styles.ProjectDisplayTagTitle}>Project Tags</h6>
          <div className={styles.ProjectDisplayTabHolder}>
            {tags.map((tag, i) => (
              <Tab key={`${tag}-i-tag`} text={tag} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDisplay
