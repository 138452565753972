import React, { Component } from 'react'
import { createRef } from 'react'
import styles from './BottomFrame.module.css'

import ProjectDisplay from './ProjectDisplay/ProjectDisplay'
import ProjectListContainer from './ProjectList/ProjectListContainer'

export default class BottomFrame extends Component {
  state = {
    projectUrl: null,
    loaded: false,
    isIntersectingView: false
  }

  constructor () {
    super()
    this.ref = createRef()
    this.checkInViewPortTimeout = null
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleWindowScroll)
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleWindowScroll)
  }

  handleWindowScroll = e => {
    this.isElementInViewport(this.ref.current)
  }

  isElementInViewport (el) {
    clearTimeout(this.checkInViewPortTimeout)
    this.checkInViewPortTimeout = setTimeout(() => {
      const rect = el.getBoundingClientRect()
      const isIntersectingView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      // console.log({ isIntersectingView })
      this.setState({ isIntersectingView })
    }, 200)
  }

  setProjectUrl = projectUrl => {
    this.setState({ projectUrl })
  }

  render () {
    const { projectUrl, isIntersectingView } = this.state
    const { windowWidth, windowHeight } = this.props

    // if (windowWidth < 1150) {
    // }

    return (
      <div
        ref={this.ref}
        className={styles.homepageChild}
        style={{
          flexDirection: windowWidth < 1150 ? 'column' : 'row-reverse'
        }}
        // style={{
        //   height: isIntersectingView ? '100%' : '90%',
        //   transform: 'height ease-in-out 1s'
        // }}
      >
        <ProjectListContainer
          setProjectUrl={this.setProjectUrl}
          projectUrl={projectUrl}
          expand={isIntersectingView}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        />
        <ProjectDisplay
          setProjectUrl={this.setProjectUrl}
          projectUrl={projectUrl}
          expand={isIntersectingView}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        />
      </div>
    )
  }
}
