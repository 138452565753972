import React, { useMemo, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'

import styles from './modal.module.css'

const Modal = ({ setModalVisible, show, intention }) => {
  const [copyClicked, setCopyClicked] = useState(false)
  const [copyFailed, setCopyFailed] = useState(false)

  // Reset copy clicked and failed on show changing!;
  useMemo(() => {
    setCopyClicked(false)
    setCopyFailed(false)
  }, [show])

  const subject = useMemo(() => {
    if (intention === 'hire') {
      return "I'm Interested in Hiring You"
    } else {
      return "Let's talk!"
    }
  }, [intention])

  const modalStyle = useSpring({
    config: {
      tension: 210,
      friction: 20
    },
    from: {
      marginTop: show ? '50px' : '0px',
      opacity: show ? 0 : 1
    },
    to: {
      marginTop: show ? '0px' : '50px',
      opacity: show ? 1 : 0
    }
  })

  const copyButtonText = useMemo(() => {
    if (copyFailed) {
      return 'Copy Failed!'
    } else {
      if (copyClicked) {
        return 'Email copied!'
      } else {
        return 'Copy Email Address'
      }
    }
  }, [copyFailed, copyClicked])

  return (
    <div
      className={styles.modalBodyContainer}
      style={{ opacity: show ? 1 : 0, pointerEvents: show ? 'all' : 'none' }}
    >
      <animated.div className={styles.modalBody} style={modalStyle}>
        <div div={styles.helloandthankyou}>
          <p
            className='pheader'
            style={{
              color: 'white',
              fontSize: '3rem',
              fontStyle: 'normal',
              fontWeight: 500,
              width: 'auto',
              margin: 0
            }}
          >
            Hello,
          </p>
          {/* <img className={styles.thankyou} src="/daffodils.jpg" alt="picture of daffodils next to a river" /> */}
        </div>
        <p
          className='pbody'
          style={{ color: 'white', fontSize: '1.3rem', marginBottom: 0 }}
        >
          Thank you for showing
        </p>
        <p
          className='pbody'
          style={{ color: 'white', fontSize: '1.3rem', marginTop: 0 }}
        >
          interest in me!
        </p>

        <button
          onClick={() => {
            navigator.permissions
              .query({ name: 'clipboard-write' })
              .then(result => {
                if (result.state === 'granted' || result.state === 'prompt') {
                  /* write to the clipboard now */
                  navigator.clipboard
                    .writeText('stuartmmellor@googlemail.com')
                    .then(
                      () => {
                        /* clipboard successfully set */
                        setCopyClicked(true)
                      },
                      () => {
                        /* clipboard write failed */
                      }
                    )
                }
              })
          }}
          className={styles.hireMe}
        >
          {copyButtonText}
        </button>
        <div style={{ width: '10px', height: '20px' }} />
        <a
          href={`mailto:stuartmmellor@googlemail.com?subject=${subject}`}
          className={styles.hireMe}
        >
          Open Email App
        </a>
        <p className='pheader' style={{ fontSize: '1.8rem', color: 'white' }}>
          Stuart
        </p>
        <button
          onClick={() => {
            setModalVisible(false)
          }}
          style={{
            width: 'auto',
            minWidth: 'auto',
            position: 'absolute',
            bottom: '-50px',
            borderRadius: '30px',
            boxShadow: '#00230e 4px 4px 0 0'
          }}
          className={styles.hireMe}
        >
          X
        </button>
      </animated.div>
    </div>
  )
}

export default Modal
