import React, { useMemo } from 'react'
import styles from './Tab.module.css'

const Tab = ({ text, frame4Width }) => {
  const frameDivStyle = useMemo(() => {
    return {
      width: frame4Width
    }
  }, [frame4Width])
  return (
    <div className={styles.tabBody} style={frameDivStyle}>
      <h6 className={styles.tabH4}>{text}</h6>
    </div>
  )
}

export default Tab
