import React, { useEffect, useMemo, useState } from 'react'
import { loadContent } from '../../../SharedFuncs/loadContent'
import ProjectList from './ProjectList'

import styles from './ProjectList.module.css'

const ProjectListContainer = ({
  setProjectUrl,
  projectUrl,
  expand,
  windowWidth
}) => {
  const [listLoaded, setListLoaded] = useState(false)
  const [listOfProjects, setListOfProjects] = useState([])

  const projectListContainerStyle = useMemo(() => {
    return {
      width: windowWidth < 1150 ? '100%' : '50%'
    }
  }, [windowWidth])

  useEffect(() => {
    if (!listLoaded) {
      loadContent('/projects.json', (err, data) => {
        if (data) {
          setListLoaded(true)
          setListOfProjects(data)

          setProjectUrl(data[0].url)
        } else {
          console.log(err)
        }
      })
    }
    return
  }, [listLoaded])

  return (
    <div
      className={styles.ProjectListContainer}
      //   style={{ height: expand ? '150%' : '100%' }}
      style={projectListContainerStyle}
    >
      <ProjectList
        setProjectUrl={setProjectUrl}
        projectUrl={projectUrl}
        listOfProjects={listOfProjects}
        windowWidth={windowWidth}
      />
    </div>
  )
}

export default ProjectListContainer
