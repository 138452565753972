import React from 'react'
import styles from './InfoPage.module.css'

const InfoPage = ({
  show,
  Title,
  text,
  image,
  sizeMode,
  narrowView,
  shortView,
  microMode,
  aboutFocus,
  mobileMode,
  veryShortPage
}) => {
  let TitleSize = mobileMode ? 1.85 : 1.95
  if (veryShortPage) {
    TitleSize = 1.75
  }

  const TitleStyle = {
    fontSize: `${TitleSize}rem`
  }
  const pHeaderStyle = {
    fontSize: `${TitleSize - 0.7}rem`
  }
  const pBodyStyle = {
    fontSize: `${TitleSize - 0.9}rem`
  }

  return (
    <div className={styles.infoPageBody}>
      <div
        className={styles.viewerFeature}
        style={{
          flexDirection: narrowView ? 'column-reverse' : 'row'
        }}
      >
        <div
          className={styles.aboutText}
          style={{
            width: narrowView || shortView ? '100%' : '400px'
          }}
        >
          {!aboutFocus && (
            <h1
              style={{
                textAlign: 'left',
                width: narrowView ? '100%' : '70%',
                ...TitleStyle
              }}
            >
              About
            </h1>
          )}
          <p className={styles.pheader} style={pHeaderStyle}>
            Dr Stuart Mellor is a creative technologist, sonic artist, and
            researcher currently based in Leeds.
          </p>
          <p className={styles.pbody} style={pBodyStyle}>
            With a PhD in acoustics, technology, and composition, I have
            accumulated a wealth of knowledge in interactive mediums and
            communication of complex real-world data. I am an engineer at heart
            and find great pleasure in problem solving and learning new
            technologies which make me an excellent research software engineer.
            From complex interactive web applications, interactive sensor-based
            exhibitions, to on-the-ground embedded devices, I have picked up a
            breadth of skills that enable me to deliver high-quality
            technological solutions.
          </p>
          <div
            style={{
              margin: 'auto',
              display: 'flex',
              maxWidth: '500px',
              justifyContent: 'space-between'
            }}
          >
            <h4
              style={{
                // width: '90px',
                height: '20px',

                background: '#defae7',
                color: '#00421b',
                fontSize: '1rem',
                padding: '5px',
                borderRadius: '2.5px',
                boxShadow: ' #00421b 4px 4px 0 0',
                border: '#00421b solid  2px'
              }}
            >
              RESEARCH
            </h4>
            <h4
              style={{
                // width: '90px',
                height: '20px',

                background: '#defae7',
                color: '#00421b',
                fontSize: '1rem',
                padding: '5px',
                borderRadius: '2.5px',
                boxShadow: ' #00421b 4px 4px 0 0',
                border: '#00421b solid  2px'
              }}
            >
              SOUND
            </h4>
            <h4
              style={{
                // width: '90px',
                height: '20px',

                background: '#defae7',
                color: '#00421b',
                fontSize: '1rem',
                padding: '5px',
                borderRadius: '2.5px',
                boxShadow: ' #00421b 4px 4px 0 0',
                border: '#00421b solid  2px'
              }}
            >
              ENVIRONMENT
            </h4>
          </div>
        </div>
        {!microMode && (
          <img
            className={styles.profileImage}
            src='https://live.staticflickr.com/65535/52098564399_8f4163650f_b.jpg'
            alt='stuart mellor posing whilst holding a sound recorder'
            style={{
              height: narrowView ? '250px' : '480px',
              transition: 'height 0.5s ease-in-out',
              objectFit: 'cover',
              width: narrowView ? '100%' : '400px'
            }}
          />
        )}
      </div>
    </div>
  )
}

export default InfoPage
